import { Avatar, Card, Text } from "@fscrypto/ui";
import { PreviewImage } from "./preview-image";
import { Link } from "@remix-run/react";
import Projects from "./projects";
import { DashboardLikes } from "~/features/dashboard-likes";
import { Timestamp } from "~/shared/components/timestamp";
import { DashboardDiscovered } from "@fscrypto/domain/dashboard";
import { VisibilityIcon } from "./visibility-icon";
import { $path } from "remix-routes";

interface DashboardCardProps {
  dashboard: EnhancedDashboard;
  activeProject?: string;
  onSelectProject?: (name: string) => void;
  showUser?: boolean;
}

export interface EnhancedDashboard extends DashboardDiscovered {
  likedByMe?: boolean;
  totalLikes?: number;
}

export const DashboardCard = ({ dashboard, onSelectProject, showUser }: DashboardCardProps) => {
  const {
    title,
    description,
    profileUrl,
    profile,
    coverImage,
    id,
    latestSlug,
    totalLikes,
    tags,
    createdAt,
    likedByMe,
    profileAvatar,
    profileUsername,
    visibility,
  } = dashboard;
  const projects = tags?.filter((o) => o.type === "project") ?? [];
  return (
    <Card asChild>
      <div className="relative">
        <Link
          to={$path("/:owner/:dashboardSlug", { owner: profile.slug, dashboardSlug: latestSlug })}
          prefetch="intent"
        >
          <VisibilityIcon visibility={visibility} />
          <PreviewImage src={coverImage?.url} alt={dashboard.title} />
          <div className="relative flex h-40 w-full flex-col space-y-1 overflow-hidden p-4">
            <h3>
              <Text color="contrast" className="pointer-events-none line-clamp-2 block" weight="medium">
                {title}
              </Text>
            </h3>
            <p className="text-muted-foreground line-clamp-2 text-xs">
              <Timestamp time={createdAt} /> {description && <> - {description}</>}
            </p>
            <div className="flex-1" />
            <div className="align-end flex w-full items-center ">
              <DashboardLikes
                initialLikeCount={totalLikes ?? 0}
                initiallyLikedByMe={likedByMe ?? false}
                dashboardId={id}
                variant={"discover"}
              />
              <Projects projects={projects} onSelect={onSelectProject} />
            </div>
          </div>
        </Link>
        {showUser && (
          <Link to={profileUrl ?? "/"}>
            <div className="absolute left-4 top-4 mr-4 flex max-w-[calc(100%_-_32px)] items-center truncate rounded-full bg-black bg-opacity-50 p-1 backdrop-blur">
              <Avatar src={profileAvatar!} size="sm" className="flex-shrink-0" alt={profileUsername!} />
              <span className="mx-3 line-clamp-1 truncate text-sm text-white">{profileUsername}</span>
            </div>
          </Link>
        )}
      </div>
    </Card>
  );
};
